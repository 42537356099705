<template>
  <div class="menu-manager-container">
    <div class="button-block">
      <el-button
      v-if="hasPermission($permissionFunctionCode.ADD)"
        round
        plain
        type="primary"
        @click="addRowData(false)">
        {{ $t('add') }}
      </el-button>
      <el-button
        type="primary"

        round
        plain
        @click="refreshDataMixin(getTableData)">
        {{ $t('refresh') }}
      </el-button>
    </div>
    <div class="table-block">
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        style="width: 100%"
        row-key="id"
        default-expand-all
        height="100%"
        :default-sort="{prop:'seq'}"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <base-no-data slot="empty"></base-no-data>
        <el-table-column
          prop="name"
          :label="$t('menuName')"
          width="250">
          <template slot-scope="scope">
            <span>{{ $t(scope.row.name) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sort"
          align="center"
          :label="$t('sort')"
          width="80">
        </el-table-column>
        <el-table-column
          :label="$t('type')"
          align="center"
          width="100">
          <template slot-scope="scope">
            <el-tag :type="scope.row.type === 0 ?'warning':''" size="mini">
              {{ `${scope.row.type === 0 ? $t('catalog') : $t('menu')}` }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="router"
          align="center"
          :label="$t('routePath')">
        </el-table-column>
        <el-table-column
          prop="component"
          align="center"
          :label="$t('componentPath')">
        </el-table-column>
        <el-table-column
          prop="icon"
          align="center"
          :label="$t('menuIcon')"
          width="120">
          <template slot-scope="scope">
            <em :class="scope.row.icon" style="font-size:18px;"></em>
          </template>
        </el-table-column>
        <el-table-column
          prop="enable"
          align="center"
          :label="$t('enable')">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.enable"
              :width="35"
              :disabled="getDisabledStatus(scope.row)"
              @change="onSwitchChange($event,scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :label="$t('operate')">
          <template slot-scope="scope">
            <!--增加-->
            <el-button
              v-if="hasPermission($permissionFunctionCode.ADD)"
              type="text"
              @click="addRowData(scope.row)">
              {{ $t('add') }}
            </el-button>
            <!--修改-->
            <el-button
              v-if="hasPermission($permissionFunctionCode.EDIT)"
              type="text"
              @click="editRowData(scope.row)">
              {{ $t('edit') }}
            </el-button>
            <!--删除-->
            <base-button-delete
              v-if="hasPermission($permissionFunctionCode.DELETE)"
              @confirm="deleteRowDataMixin(scope.row,getTableData)">
            </base-button-delete>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <base-form-add-or-edit
      v-if="addDialogVisible"
      :visible="addDialogVisible"
      :url-save="this.saveUrl"
      :url-update="this.saveUrl"
      :fields="fields"
      @closed="addDialogVisible=false"
      @submit-success="getTableData">
    </base-form-add-or-edit>
  </div>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'

export default {
  name: 'MenuManager',
  mixins: [baseMixin, baseTableMixin],
  data () {
    return {
      fields: [
        {
          fieldName: 'parentId',
          fieldType: 'Number',
          default: undefined,
          label: 'superiorMenu',
          formConfig:
            {
              type: 'cascader',
              options: [],
              showAllLevels: false,
              props: {
                checkStrictly: true,
                emitPath: false
              }
            }
        },
        {
          fieldName: 'name',
          label: 'menuName',
          default: '',
          rules: 'required'
        },
        {
          fieldName: 'type',
          fieldType: 'Number',
          default: 1,
          label: 'type',
          formConfig:
            {
              type: 'radio',
              options: [
                {
                  value: 0,
                  text: 'catalog'
                },
                {
                  value: 1,
                  text: 'menu'
                }
              ]
            }
        },
        {
          fieldName: 'router',
          label: 'routePath',
          default: undefined
        },
        {
          fieldName: 'icon',
          label: 'icon',
          rules: 'required',
          default: '',
          formConfig:
            {
              type: 'icon'
            }
        },
        {
          fieldName: 'component',
          label: 'componentPath',
          default: undefined
        },
        {
          fieldName: 'sort',
          fieldType: 'Number',
          default: null,
          label: 'sort',
          rules: 'required',
          formConfig:
            {
              type: 'inputNumber',
              min: 1
            }
        },
        {
          fieldName: 'enable',
          fieldType: 'Boolean',
          default: true,
          label: 'enable',
          formConfig:
            {
              type: 'switch'
            }
        }
      ]
    }
  },

  created () {
    this.queryUrl = this.$apis.queryMenuTree
    this.saveUrl = this.$apis.saveMenu
    this.deleteUrl = this.$apis.deleteMenu
    this.getTableData()
  },

  methods: {
    getTableData () {
      /**
       * 获取表格数据
       */
      this.$request.post({
        url: this.$apis.queryMenuTree
      }).then(data => {
        if (data?.code === 1000) {
          const tableData = data.data || []
          const setChildren = (data) => {
            data.forEach(item => {
              if (item.children?.length) {
                setChildren(item.children)
              } else {
                item.children = []
              }
            })
          }
          setChildren(tableData)
          this.tableData = tableData
          // 刷新导航标签数据
          this.refreshNavigationTag(tableData)
        }
      })
    },

    setValueAndLabel (menus) {
      /**
       * 设置级联选择器的options的value和label
       */
      menus.forEach(menu => {
        menu.value = menu.id
        menu.label = this.$t(menu.name)
        if (menu.children?.length) {
          this.setValueAndLabel(menu.children)
        } else {
          menu.children = undefined
        }
      })
    },

    addRowData (row) {
      /**
       * 增加数据
       * @param {Object} row 行数据
       */
      // 重置fields数据
      this.fields = this.$options.data().fields
      // 删除id字段
      this.addOrDeleteIdFieldMixin('del')
      if (row) {
        // 存在row，则在当前层级下添加菜单信息
        row = this.$_.cloneDeep(row)
        this.setValueAndLabel([row])
        this.fields[0].default = row.id
        this.fields[0].formConfig.options = [row]
      } else {
        // 不存在row，则在任意层级下添加菜单信息
        const tableData = this.$_.cloneDeep(this.tableData)
        this.setValueAndLabel(tableData)
        // 目录设置父级ID为0
        this.fields[0].default = '0'
        this.fields[0].formConfig.options = tableData
      }
      this.addDialogVisible = true
    },

    editRowData (row) {
      /**
       * 编辑数据
       * @param {Object} row 行数据
       */
      this.addOrDeleteIdFieldMixin('add')
      const tableData = this.$_.cloneDeep(this.tableData)
      this.setValueAndLabel(tableData)
      this.fields[0].formConfig.options = tableData
      // 设置各字段的初始值
      this.fields.forEach(field => {
        field.default = row[field.fieldName]
      })
      this.fields[0].default = row.parentId
      this.fields[0].disabled = true
      this.addDialogVisible = true
    },

    getDisabledStatus (row) {
      /**
       * 每行switch开关的状态
       * @param {Object} row 行数据
       */
      if (row.children?.length) {
        return row.children.some(item => item.enable)
      }
      return false
    },

    onSwitchChange (value, row) {
      /**
       * 监听switch值变化，更新启用状态
       * @param {Boolean} value 变化后的值
       * @param {Object} row 行数据
       */
      const id = row.id
      this.$request.post({
        url: this.saveUrl,
        data: {
          id,
          enable: value
        }
      }).then(data => {
        if (data?.code === 1000) {
          this.$message.success(this.$t('saveSuccess'))
          this.getTableData()
        }
      })
    },

    refreshNavigationTag (menus) {
      /**
       * 刷新导航标签数据
       */
      const allMenus = []
      // 获取非树结构菜单数据
      const getTabularMenu = (menus) => {
        menus.forEach(item => {
          if (item.children?.length) {
            getTabularMenu(item.children)
          } else if (item.enable) {
            allMenus.push(item)
          }
        })
      }
      getTabularMenu(menus)
      const navigationTags = this.$store.state.navigationTags.filter(tag => {
        // 筛选出包含在非树结构菜单中的导航标签
        return allMenus.some(menu => menu.name === tag.name)
      })
      // 更新导航标签
      this.$store.commit('setNavigationTags', navigationTags)
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-manager-container {
  height: 100%;
  width: 100%;

  .button-block {
    text-align: right;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
  }

  /deep/ .table-block {
    width: 100%;
    height: calc(100% - 58px);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 3px #ededf3;
    .el-table th {
      background-color: #0F376A;
      color: #ffffff;
      font-size: 13px;
      font-weight: bold;
      height: 50px;
    }
    .el-table td {
      background-color: #FCFCFD;
    }
    .el-table__body tr.hover-row>td, .el-table--enable-row-hover .el-table__body tr:hover>td {
      background-color: #E1E3EA;
    }
    .el-table__fixed-right-patch {
      background-color: #0F376A;
    }
    .el-table__body tr.current-row>td {
      background-color: #e7ebf9;
    }
  }
}
</style>
